import IconGooglePin from '@public/new-icons/icon/icon_google-pin.svg';

type Props = {
  /** 所在地 */
  areaText: string;
};

export function LabelArea({ areaText }: Props) {
  return (
    <div className='inline-flex items-center gap-xxs'>
      <div className='h-5 w-5'>
        <IconGooglePin />
      </div>
      <div className='text-label-medium text-secondary'>{areaText}</div>
    </div>
  );
}
