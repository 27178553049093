type nil = null | undefined;

export function isEmpty(array: ReadonlyArray<unknown> | nil): array is nil | [];
export function isEmpty(array: ArrayLike<unknown> | nil): array is nil | { length: 0 };
export function isEmpty(array: { length: number } | nil): boolean {
  return !array || array.length === 0;
}

export function isNotEmpty<T extends ArrayLike<unknown>>(array: T | nil): array is T {
  return !isEmpty(array);
}
