import tw from 'clsx';

type Props = {
  /** tagに表示するtext(label) */
  label: string;
  /** labelのsize。3種類から選択 */
  size: 'sm' | 'md' | 'lg';
  /** type: tagの種類 */
  type: 'primary' | 'secondary';
  bg?: string;
};

const PADDING_VARIANTS = {
  sm: 'px-s__sp py-xs__sp lg:px-s lg:py-xs',
  md: 'px-m__sp py-s__sp lg:px-m lg:py-s',
  lg: 'px-l__sp py-m__sp lg:px-l lg:py-m',
};

const TEXT_SIZE_VARIANTS = {
  sm: 'text-label-small',
  md: 'text-label-medium',
  lg: 'text-label-large',
};

const TAG_VARIANTS = {
  primary: 'bg-blue-5 font-bold text-blue-80',
  secondary: 'bg-primary border border-solid border-light text-regular',
};

export function DecorativeTag({ label, size = 'md', type = 'primary', bg }: Props) {
  return (
    <div
      className={tw(
        `inline-block rounded-corner-s`,
        `${PADDING_VARIANTS[size]}`,
        TAG_VARIANTS[type],
        bg ? `${bg} border-none` : null,
      )}
    >
      <div className={`${TEXT_SIZE_VARIANTS[size]}`}>{label}</div>
    </div>
  );
}
