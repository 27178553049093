import { isNotEmpty } from '~/utils/array';

import { Avatar } from '../Avatar/Avatar';
import { LabelArea } from '../Label/LabelArea';
import { DecorativeTag } from '../Tag';
import { SusnetLink } from '../_wrapper/SusnetLink';

type Props = {
  areaText?: string;
  companyLogo?: string;
  companyName?: string;
  /** cardの遷移先 */
  link: string;
  categoryList?: string[];
  title: string;
};

export function CardOrg({
  areaText,
  companyLogo,
  companyName,
  link = '',
  categoryList,
  title,
}: Props) {
  return (
    <div className='flex w-[202px] max-w-[202px] flex-col rounded-corner-l bg-primary shadow-elevation-level1 transition-all hover:shadow-elevation-level3'>
      <SusnetLink href={link}>
        <div className='flex flex-col items-center pt-6'>
          <Avatar size={64} src={companyLogo} alt={companyName} />
        </div>
        <div className='flex items-center justify-between gap-s px-6 py-2 lg:gap-s'>
          <div className='flex items-center justify-start gap-s'>
            <div className='line-clamp-1 flex-1 text-title-strong-x-small text-primary'>
              {companyName}
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-s__sp px-xxl__sp pb-xl__sp pt-l__sp lg:gap-s lg:px-xxl lg:pb-xl'>
          <div className='line-clamp-2 text-title-strong-x-small text-regular'>{title}</div>
          {isNotEmpty(areaText) && (
            <div className='flex justify-end p-xxs__sp lg:p-xxs'>
              <LabelArea areaText={areaText} />
            </div>
          )}
          {isNotEmpty(categoryList) && (
            <ul className='flex flex-wrap justify-start gap-s__sp lg:gap-s'>
              {categoryList.map((category, index) => (
                <li key={index}>
                  <DecorativeTag type={'secondary'} label={category} size={'sm'} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </SusnetLink>
    </div>
  );
}
