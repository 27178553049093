import Link from 'next/link';

import { isNotEmpty } from '~/utils/array';

import { Avatar } from '../Avatar/Avatar';
import { SusnetImage } from '../_wrapper/SusnetImage';

type Props = {
  companyLogo?: string;
  /** isShowCompanyInfoがtrueの場合に表示。企業名。 */
  companyName?: string;
  /** 企業情報を表示させたい場合にtrueにする */
  link: string;
  /** postに表示されるthumbnail */
  src?: string;
  title: string;
};

export function CardPresentation({ companyLogo, companyName, link = '', src, title }: Props) {
  return (
    <Link href={link}>
      <div className='flex w-[324px] max-w-[324px] flex-col rounded-corner-l bg-primary shadow-elevation-level1 transition-all hover:shadow-elevation-level3 lg:w-[420px] lg:max-w-[420px]'>
        {isNotEmpty(src) && src !== '' && (
          <div className='relative h-[240px] w-full rounded-t-corner-l'>
            <SusnetImage
              className='rounded-t-corner-l object-cover'
              src={src ?? '/dummy/900-600.png'}
              alt='image'
              fill
              sizes='100vw'
            />
          </div>
        )}
        <div className='flex flex-col gap-s__sp px-xxl__sp pb-xl__sp pt-l__sp lg:gap-s lg:px-xxl lg:pb-xl'>
          <div className='flex items-center justify-between gap-s lg:gap-s'>
            <div className='flex items-center justify-start gap-s'>
              <Avatar size={48} src={companyLogo} alt={companyName} />
              <div className='flex flex-1 flex-col'>
                <div className='line-clamp-2 text-title-strong-x-small text-primary'>{title}</div>
                <div className='line-clamp-1 text-body-large text-primary'>{companyName}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
