import Link from 'next/link';

import { isNotEmpty } from '~/utils/array';

import { Avatar } from '../Avatar/Avatar';
import { LabelArea } from '../Label/LabelArea';
import { DecorativeTag } from '../Tag';
import { SusnetImage } from '../_wrapper/SusnetImage';

type Props = {
  areaText?: string;
  categoryList?: string[];
  /** cardの遷移先 */
  link: string;
  /** cardに表示されるthumbnail */
  src?: string;
  tagList?: string[];
  text: string;
  title: string;
};

export function CardActivityReportDetail({
  areaText,
  categoryList,
  link = '',
  src,
  tagList,
  text,
  title,
}: Props) {
  return (
    <Link href={link}>
      <div className='flex w-full max-w-[324px] flex-col rounded-corner-l bg-primary shadow-elevation-level1 transition-all hover:shadow-elevation-level3 lg:max-w-[420px]'>
        {isNotEmpty(src) && src !== '' && (
          <div className='relative h-[240px] w-full rounded-t-corner-l'>
            <SusnetImage
              className='rounded-t-corner-l object-cover'
              src={src ?? '/dummy/900-600.png'}
              alt='image'
              fill
              sizes='100vw'
            />
          </div>
        )}
        <div className='flex flex-col gap-s__sp px-xxl__sp pb-xl__sp pt-l__sp lg:gap-s lg:px-xxl lg:pb-xl'>
          <div className='line-clamp-2 text-title-strong-x-small text-primary'>{title}</div>
          <div className='line-clamp-2 text-body-medium text-regular'>{text}</div>
          {isNotEmpty(areaText) && (
            <div className='flex justify-end p-xxs__sp lg:p-xxs'>
              <LabelArea areaText={areaText} />
            </div>
          )}
          {isNotEmpty(categoryList) && (
            <ul className='flex flex-wrap justify-end gap-s__sp lg:gap-s'>
              {categoryList.map((category, index) => (
                <li key={index}>
                  <DecorativeTag type={'secondary'} label={category} size={'sm'} />
                </li>
              ))}
            </ul>
          )}
          {isNotEmpty(tagList) && (
            <ul className='flex flex-wrap justify-end gap-s__sp lg:gap-s'>
              {tagList.map((tag, index) => (
                <li key={index}>
                  <DecorativeTag type={'secondary'} label={tag} size={'sm'} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Link>
  );
}
