import Link from 'next/link';

import { isNotEmpty } from '~/utils/array';

import { Avatar } from '../Avatar/Avatar';
import { LabelArea } from '../Label/LabelArea';
import { DecorativeTag } from '../Tag';
import { SusnetImage } from '../_wrapper/SusnetImage';

type Props = {
  areaText?: string;
  categoryList?: string[];
  companyLogo?: string;
  companyName?: string;
  /** cardの遷移先 */
  link: string;
  /** cardに表示されるthumbnail */
  src?: string;
  tagList?: string[];
  text: string;
  title: string;
  /** cardに表示するlabel */
  label?: string;
  status?: string;
  /** 募集 */
  recruitmentList?: string[];
  index?: {
    label: string;
    value: number;
    unit: string;
  };
};

export function CardProjectDetail({
  areaText,
  categoryList,
  companyLogo,
  companyName,
  link = '',
  src,
  tagList,
  text,
  title,
  label,
  status,
  recruitmentList,
  index,
}: Props) {
  return (
    <Link href={link}>
      <div className='flex w-[324px] max-w-[324px] flex-col rounded-corner-l bg-primary shadow-elevation-level1 transition-all hover:shadow-elevation-level3 lg:w-[420px] lg:max-w-[420px]'>
        {isNotEmpty(src) && src !== '' && (
          <div className='relative h-[240px] w-full rounded-t-corner-l'>
            <SusnetImage
              className='rounded-t-corner-l object-cover'
              src={src ?? '/dummy/900-600.png'}
              alt='image'
              fill
              sizes='100vw'
            />
            {isNotEmpty(label) && (
              <div className='absolute left-4 top-4'>
                <DecorativeTag type={'secondary'} label={label} size={'lg'} />
              </div>
            )}
          </div>
        )}
        <div className='flex items-center justify-between gap-s px-6 py-2 lg:gap-s'>
          <div className='flex w-full flex-row items-center justify-start gap-s'>
            <Avatar size={48} src={companyLogo} alt={companyName} />
            <div className='line-clamp-1 flex-1 text-body-strong-large text-primary'>
              {companyName}
            </div>
            {isNotEmpty(status) && (
              <DecorativeTag type={'secondary'} label={status} size={'sm'} bg={'!bg-[#ABE5A1]'} />
            )}
          </div>
        </div>
        <div className='flex flex-col gap-s__sp px-xxl__sp pb-xl__sp pt-l__sp lg:gap-s lg:px-xxl lg:pb-xl'>
          <div className='line-clamp-2 text-title-strong-x-small text-primary'>{title}</div>
          <div className='border-b-solid line-clamp-2 border-b-[1px] border-b-light pb-2 text-body-medium text-regular'>
            {text}
          </div>
          {index && (
            <div className='flex w-full flex-col items-center bg-highlight px-2 py-1'>
              <p className='w-full text-left text-body-strong-small text-regular'>{index.label}</p>
              <p className='w-full text-right text-body-strong-large'>
                <span className='text-en-title-strong-medium text-accent'>
                  {index.value.toLocaleString()}
                </span>
                <span className='ml-1'>{index.unit}</span>
              </p>
            </div>
          )}
          {isNotEmpty(recruitmentList) && (
            <div className='mt-4 flex flex-col items-start gap-2'>
              <p className='text-body-medium'>募集</p>
              <div className='flex flex-row flex-wrap gap-2'>
                {recruitmentList.map((recruitmentItem, index) => (
                  <DecorativeTag key={index} type={'primary'} label={recruitmentItem} size={'lg'} />
                ))}
              </div>
            </div>
          )}
          {isNotEmpty(areaText) && (
            <div className='flex justify-end p-xxs__sp lg:p-xxs'>
              <LabelArea areaText={areaText} />
            </div>
          )}
          {isNotEmpty(categoryList) && (
            <ul className='flex flex-wrap justify-end gap-s__sp lg:gap-s'>
              {categoryList.map((category, index) => (
                <li key={index}>
                  <DecorativeTag type={'secondary'} label={category} size={'sm'} />
                </li>
              ))}
            </ul>
          )}
          {isNotEmpty(tagList) && (
            <ul className='flex flex-wrap justify-end gap-s__sp lg:gap-s'>
              {tagList.map((tag, index) => (
                <li key={index}>
                  <DecorativeTag type={'secondary'} label={tag} size={'sm'} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Link>
  );
}
